import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

export const store = createStore({
    state() {
        return {
            age: false,
            sliderConfig: {
                speed: 5
            },
            language: "en",
        }
    },
    mutations: {
        acceptAge(state) {
            state.age = true;
        },
        changeSpeed(state, payload) {
            state.sliderConfig.speed = payload.speed;
        },
        changeLanguage(state, payload) {
            state.language = payload.langCode;
        }
    },
    plugins: [createPersistedState({
        storage: {
            getItem: key => Cookies.get(key),
            setItem: (key, value) => Cookies.set(key, value, {expires: 7, secure: true}),
            removeItem: key => Cookies.remove(key)
        }
    })],
})