<template>
  <swiper
      :auto-height="false"
      :autoplay="sliderConfig.autoplay"
      :direction="'vertical'"
      :effect="'fade'"
      :lazy="{loadPrevNext: true, loadPrevNextAmount: 3}"
      :mousewheel="{
        forceToAxis: true,
        sensitivity: 1,
        releaseOnEdges: true,
      }"
      :prevent-clicks="false"
      :modules="modules"
      :prevent-clicks-propagation="false"

      :rewind="true"
      :simulateTouch="false"
      @wheel="onMouseWheelScrolled"

      :short-swipes="false"
      class="mySwiper"
      @afterInit="onSwiper"
      @slideChange="onSlideChange"

  >
    <swiper-slide v-for="(image, idx) in images" :key="image.id">
      <div class="position-relative">
        <img v-if="idx>=3" :alt="image.id" :data-src="image.url" @click="toggleOverlay"
             class="swiper-lazy" src="data:image/gif;base64,R0lGODlhAQABAGAAACH5BAEKAP8ALAAAAAABAAEAAAgEAP8FBAA7">
        <div v-if="idx>=3" class="swiper-lazy-preloader"></div>
        <img v-else :alt="image.id" :data-src="image.url" class="swiper-lazy lazy_loading"
             src="https://cookembu.sirv.com/Web/dummy.jpg" @click="toggleOverlay">
<!--        <ImageSearch :image="image" class="image-search"/>-->
        <FavoriteButton class="favorite-button" v-bind:image="image" @toggle="toggleFavorite(image)"/>
        <div ref="overlay" class="image-overlay image-overlay-inactive">
          <div class="position-relative">
            <SliderController v-bind:is-playing="sliderConfig.isPlaying" v-bind:speed="sliderConfig.speed"
                              @change="onChangeController"/>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import axios from "axios";
import {Swiper, SwiperSlide,} from "swiper/vue";
import {Autoplay, EffectFade, Lazy, Mousewheel} from "swiper";
import SliderController from "@/components/slide/SliderController";
import FavoriteButton from "@/components/slide/FavoriteButton";
// import ImageSearch from "@/components/slide/ImageSearch";
import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"
import "swiper/css/lazy"
import "../../assets/style.css"
import lazyLoading from "../../assets/lazyloading"

import URLS from "../../config/api_urls.js"
import {store} from "@/store";


export default {
  name: 'MainSlider',
  components: {
    Swiper,
    SwiperSlide,
    SliderController,
    FavoriteButton,
    // ImageSearch
  },
  setup() {
    return {
      modules: [Autoplay, EffectFade, Lazy, Mousewheel],
    };
  },
  data() {
    return {
      tags: this.getTagsFromQuery(),
      imageApiUrl: this.getImageApiUrl(),
      images: null,

      sliderConfig: {
        speed: this.getSpeedMsOnStore(),
        autoplay: {delay: this.getSpeedMsOnStore(), disableOnInteraction: false},
        isPlaying: true,
      },

      swiper: null,
    }
  },
  mounted() {
    this.images = []
    axios.get(this.imageApiUrl)
        .then(response => {
          for (let i = 0; i < process.env.VUE_APP_IMAGE_BLOCKS; i++) {
            this.images = this.images.concat(response.data)
          }
          this.$nextTick(() => {
            lazyLoading.lazyLoading()
          })
        })
  },
  methods: {
    onSwiper: function (swiper) {
      this.swiper = swiper;
    },
    onDoubleClickSwiper: function (_swiper) {
      this.toggleAutoPlay();
    },
    toggleAutoPlay: function () {
      if (!this.swiper.autoplay.running) {
        this.swiper.autoplay.start();
        this.sliderConfig.isPlaying = true;
      } else {
        this.swiper.autoplay.stop();
        this.sliderConfig.isPlaying = false;
      }
    },

    onSlideChange: function (swiper) {
      // 取得枚数 * ブロック数 = 最大
      // 各ブロックでラストから3枚目に新たに取得
      // 次のブロックへURLを更新
      // console.log(swiper.activeIndex, swiper.slides.length);
      const idx = swiper.activeIndex;
      const imageNum = process.env.VUE_APP_IMAGE_NUM
      const imageBlock = process.env.VUE_APP_IMAGE_BLOCKS
      if (idx % imageNum === imageNum - 3) {
        axios.get(this.imageApiUrl)
            .then(response => {
              let block = (idx - idx % imageNum) / imageNum + 1
              let i = block * imageNum
              const max = imageNum * imageBlock
              for (let j = 0; j < imageNum; j++) {
                this.images[(i + j) % max] = response.data[j]
                // console.log(`replaced: ${(i + j) % max} <- ${j}`)
              }
            })
      }
    },

    onMouseWheelScrolled: function (event) {
      if (this.swiper.activeIndex === this.images.length - 1 && event.wheelDelta < 0) {
        this.swiper.slideTo(0)
      } else if (this.swiper.activeIndex === 0 && event.wheelDelta > 0) {
        this.swiper.slideTo(this.images.length - 1);
      }
    },

    getSpeedMsOnStore: function () {
      return store.state.sliderConfig.speed * 1000;
    },

    getImageApiUrl: function () {
      let tags = this.getTagsFromQuery()
      if (tags === undefined) {
        tags = []
      }

      if (tags.length === 0) {
        return `${URLS.API_URL_IMAGE}?n=${process.env.VUE_APP_IMAGE_NUM}`
      } else {
        const tagStr = tags.join("&tags=")
        return `${URLS.API_URL_IMAGE}?n=${process.env.VUE_APP_IMAGE_NUM}&tags=${tagStr}`
      }
    },

    onChangeController: function (payload) {
      this.sliderConfig.speed = this.getSpeedMsOnStore();
      this.sliderConfig.isPlaying = payload.isPlaying;
    },

    toggleOverlay: function () {
      const img = this.$refs.overlay
      img.forEach(ele => {
        ele.classList.toggle("image-overlay-active")
        ele.classList.toggle("image-overlay-inactive")
      });
    },

    getTagsFromQuery: function () {
      if (this.$route.query.tags === undefined) {
        return []
      }
      return Array.isArray(this.$route.query.tags)
          ? this.$route.query.tags
          : this.$route.query.tags.split(",")
    },

    toggleFavorite: function (image) {
      image.is_favorite = !image.is_favorite
    }
  },
  watch: {
    sliderConfig: {
      handler: function (_newVal, _oldVal) {
        this.swiper.params.autoplay.delay = this.sliderConfig.speed
        if (this.swiper.autoplay.running !== this.sliderConfig.isPlaying) {
          this.toggleAutoPlay();
        }
      },
      deep: true,
    }
  }
}
</script>

<style scoped>
.slider-controller {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.image-overlay {
  position: absolute;
  width: 100%;
  height: 25%;
  min-height: 100px;
  bottom: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  background-color: rgba(0, 0, 0, 50%);
}

.image-overlay-inactive {
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease;
}

.image-overlay-active {
  visibility: visible;
  opacity: 1;
  transition: all .5s ease;
}

.favorite-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 2.5rem;
  color: hotpink;
  margin: .5rem;
}

/*.image-search {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  height: 2.5rem;*/
/*  color: black;*/
/*  margin: .5rem;*/
/*}*/
</style>
