<template>
  <div id="root" class="d-flex flex-column h-100">
    <Header class="flex-grow-0"/>
    <div id="content" class="d-flex flex-row mh-100 h-100">
      <div class="d-none d-lg-block col-lg-2">
        <AdsRanking7D/>
      </div>
      <div class="col-12 col-lg-8">
        <router-view></router-view>
      </div>
      <div class="d-none d-lg-block col-lg-2">
        <AdsRanking1Y/>
      </div>
    </div>
    <AgeCheck v-if="!store.state.age"></AgeCheck>
  </div>
</template>

<script>
import Header from "./components/common/Header.vue";
import AgeCheck from "@/components/AgeCheck";
import AdsRanking7D from "@/components/ads/AdsRanking7D";
import AdsRanking1Y from "@/components/ads/AdsRanking1Y";

import {store} from "@/store";

import {titleKeys} from "@/router/title";

export default {
  name: 'App',
  components: {
    Header,
    AgeCheck,
    AdsRanking1Y,
    AdsRanking7D,
  },
  data() {
    return {
      store,
    }
  },
  watch: {
    "$route"(to, _from) {
      // dynamic title
      const title = this.$t(to.meta.title)
      if (title !== "") {
        document.title = `${title} | Hentic`
      } else {
        document.title = "Hentic"
      }
    }
  },
  mounted() {
    document.documentElement.lang = this.$i18n.locale

    // i18n
    const messages = {
      en: {},
      ja: {}
    }
    // title
    messages.en[titleKeys.home] = ""
    messages.ja[titleKeys.home] = ""
    messages.en[titleKeys.slider] = "Random Images"
    messages.ja[titleKeys.slider] = "ランダム画像"
    messages.en[titleKeys.categoryList] = "Category List"
    messages.ja[titleKeys.categoryList] = "カテゴリ一覧"

    this.$i18n.setLocaleMessage("en", messages.en)
    this.$i18n.setLocaleMessage("ja", messages.ja)
  }
}
</script>

<style>
#root {
  color: #2c3e50;
}

#content > div {
  height: 100%;
}

age-check {
  z-index: 5000;
}

</style>
