import {createRouter, createWebHistory} from 'vue-router'
import MainSlider from '../components/slide/MainSlider.vue'
import Index from "../components/Index.vue";
import CategoryList from "@/components/CategoryList";
import {titleKeys} from "@/router/title";

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index,
        meta: {
            title: titleKeys.home
        }
    },
    {
        path: "/image",
        name: "main-slider",
        component: MainSlider,
        props: true,
        meta: {
            title: titleKeys.slider
        }
    },
    {
        path: "/category",
        name: "category-list",
        component: CategoryList,
        meta: {
            title: titleKeys.categoryList
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router