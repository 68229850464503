<template>
  <div>
    <font-awesome-icon v-if="!image.is_favorite" icon="fa-regular fa-heart" @click="onClickFavoriteBtn"/>
    <font-awesome-icon v-if="image.is_favorite" icon="fa-solid fa-heart-circle-check" @click="onClickFavoriteBtn"/>
  </div>
</template>

<script>
export default {
  name: "FavoriteButton",
  props: {
    image: Object,
  },
  methods: {
    onClickFavoriteBtn: function () {
      this.$emit("toggle")
    }
  }
}
</script>

<style scoped>
.fa-heart, .fa-heart-circle-check {
  height: 100%;
}
</style>