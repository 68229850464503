<template>
  <div>
    <div class="text-center bg-white rounded">
      <h4 class="fw-bold py-1">{{ $t("message.randomCategory") }}</h4>
    </div>
    <div class="row px-lg-2">
      <div v-for="tag in tagList" :key="tag.id" class="col-12 col-md-6 col-xl-4 col-xxl-3">
        <div class="card col bg-transparent rounded-3 overflow-hidden">
          <div class="img_black_layer">
            <img :alt="tag.id" :data-src="tag.thumbnail_url" class="card-img lazy_loading" loading="lazy"
                 src="https://cookembu.sirv.com/Web/dummy.jpg">
          </div>
          <a class="text-white " href="#" @click="moveToSlider(tag.id)">
            <div class="card-img-overlay d-flex justify-content-center align-items-center">
              <h5 class="card-title">{{ tag.name }} ({{ tag.image_num }})</h5>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import router from "@/router";
import lazyLoading from "@/assets/lazyloading"

export default {
  name: "RandomCategory",
  data() {
    return {
      tagList: [],
      selectedTags: []
    }
  },
  methods: {
    moveToSlider: function (tag) {
      router.push({
        name: "main-slider",
        query: {tags: tag}
      })
    },
  },
  created() {
    // get random tags
    const url = `${process.env.VUE_APP_API_URL_ORIGIN}${process.env.VUE_APP_API_PATH_TAG_RANDOM}?n=12&lang=${this.$i18n.locale}`
    axios.get(url).then(response => {
      this.tagList = response.data
      this.$nextTick(() => {
        lazyLoading.lazyLoading()
      })
    })
  },
  i18n: {
    messages: {
      en: {
        message: {
          randomCategory: "Pickup Category",
        }
      },
      ja: {
        message: {
          randomCategory: "おすすめカテゴリ",
        }
      }
    }
  }
}
</script>

<style scoped>
.card-img {
  aspect-ratio: 4 / 3;
  object-fit: cover;
  overflow: hidden;
}

.card-img-overlay {
  background-color: black;
  opacity: .5;
}

.card-img-overlay:hover {
  opacity: .75;
}
</style>