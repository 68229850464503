async function lazyLoading() {
    if ("loading" in HTMLImageElement.prototype) {
        const imageList = document.querySelectorAll("img.lazy_loading")
        imageList.forEach(image => {
            image.src = image.dataset.src
        })
    } else {
        const lazySizes = await import("lazysizes")
        lazySizes.init()
    }
}

export default {
    lazyLoading,
}
