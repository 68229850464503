<template>
  <div class="index container-md">
    <div>
      <img alt="" class="background" src="https://cookembu.sirv.com/Web/low_bg.jpg">
    </div>
    <div class="pt-3">
      <SiteNotices/>
    </div>
    <div class="pt-3">
      <RandomCategory/>
    </div>
    <div class="border-bottom my-3 border-secondary"></div>
    <div>
      <RandomImage/>
    </div>
  </div>
</template>

<script>
import RandomCategory from "@/components/index/RandomCategory";
import RandomImage from "@/components/index/RandomImage";
import SiteNotices from "@/components/index/SiteNotices";

export default {
  name: "IndexPage",
  components: {
    RandomCategory,
    RandomImage,
    SiteNotices,
  }
}
</script>

<style scoped>
.index {
  height: 100%;
  width: 100%;
}

.background {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  filter: blur(10px) grayscale(50%);
}
</style>