<template>
  <div class="age-check d-flex flex-column align-items-center justify-content-center">
    <div class="dialog border rounded border-3">
      <div class="p-lg-5">
        <div>
          <h3 class="text-danger">{{ $t("message.ageDialog") }}</h3>
        </div>
        <div class="d-flex justify-content-evenly">
          <button class="btn btn-dark d-block" @click="history.back()">{{ $t("message.ageButtonBack") }}</button>
          <button class="btn btn-primary d-block" @click="acceptAge">{{ $t("message.ageButtonOK") }}</button>
        </div>
      </div>
      <div>
        <LanguageButton class="text-end"/>
      </div>
    </div>
  </div>
</template>

<script>

import LanguageButton from "@/components/common/LanguageButton";

export default {
  name: "AgeCheck",
  components: {
    LanguageButton
  },

  methods: {
    acceptAge: function () {
      this.$store.commit(
          "acceptAge"
      )
    }
  },
  i18n: {
    messages: {
      en: {
        message: {
          ageDialog: "Hentic is an ADULTS ONLY website!",
          ageButtonOK: "I'm 18+ ENTER",
          ageButtonBack: "Exit",
        }
      },
      ja: {
        message: {
          ageDialog: "Henticは、成人向けサイトです!",
          ageButtonOK: "18才以上です",
          ageButtonBack: "戻る",
        }
      }
    }
  }
}
</script>

<style scoped>
.age-check {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 95%);
}

.age-check .dialog {
  background-color: rgb(231, 230, 230);
}
</style>