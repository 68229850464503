import {createApp} from 'vue';
import App from './App.vue';
import router from "@/router";
import {store} from "@/store";
import {createI18n} from "vue-i18n";
import BootstrapVue3 from 'bootstrap-vue-3';
import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faHeartCircleCheck, faMagnifyingGlass, faPlay, faStop, faUser} from "@fortawesome/free-solid-svg-icons";
import {faHeart} from "@fortawesome/free-regular-svg-icons";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

library.add(faUser, faPlay, faStop, faHeartCircleCheck, faHeart, faMagnifyingGlass);

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(BootstrapVue3);
app.use(router);
app.use(store);


export const i18n = createI18n(
    {
        locale: store.state.language,
        fallbackLocale: 'ja',
    }
)
app.use(i18n);
app.mount('#app');
