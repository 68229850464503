<template>
  <div class="language-button">
    <div class="dropdown">
      <a id="languageDropDownBtn" aria-expanded="false" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown"
         href="#"
         role="button">
        <span :class="`fi fi-${localeFlag()}`"></span>
      </a>
      <ul aria-labelledby="languageDropDownBtn" class="dropdown-menu">
        <li v-for="lang in availableLanguageList" :key="lang" @click="changeLang(lang.code)">
          <a class="dropdown-item" href="#"><span :class="`fi fi-${lang.flag}`"></span>
            <span>{{ lang.display }}</span></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "flag-icons/css/flag-icons.min.css"
import {ALLOWED_LANGUAGES, DEFAULT_LANGUAGES} from "@/config/parameters"
import {i18n} from "@/main";

export default {
  name: "LanguageButton",
  data() {
    return {
      languageList: ALLOWED_LANGUAGES
    }
  },
  computed: {
    availableLanguageList: function () {
      // keys: ["ja", "en"] -> filter: ["en"] -> map [{en: code: "en", flag: "us" ...}]
      const available = []
      Object.keys(this.languageList)
          .filter(langCode => this.$i18n.locale !== langCode).map(langCode => {
        available.push(this.languageList[langCode])
      })
      return available
    }
  },
  methods: {
    localeFlag: function () {
      if (!this._checkValidLanguageCode(this.$i18n.locale)) {
        this._updateLocale(DEFAULT_LANGUAGES)
      }
      const locale = this.$i18n.locale
      return ALLOWED_LANGUAGES[locale].flag
    },

    changeLang: function (langCode) {
      if (!this._checkValidLanguageCode(langCode)) {
        langCode = DEFAULT_LANGUAGES
      }
      this._updateLocale(langCode)
    },


    _checkValidLanguageCode(langCode) {
      return langCode in ALLOWED_LANGUAGES
    },
    _updateLocale(langCode) {
      i18n.global.locale = langCode
      this.$store.commit(
          "changeLanguage",
          {langCode: langCode}
      )
      this.$router.go(0)
    }
  }
}
</script>

<style scoped>
.fi {
  box-shadow: 1px 1px gray;
  margin: 0 .25rem;
}

.dropdown-item .fi {
  margin: 0 .5rem 0 0;
}

.dropdown-menu {
  z-index: 5000;
}
</style>