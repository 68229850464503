<template>
  <div>
    <button class="btn btn-primary d-block mx-auto" @click="moveToSlider">{{ $t("message.startRandomImage") }}</button>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "RandomImage",
  methods: {
    moveToSlider: function () {
      router.push({
        name: "main-slider",
      })
    },
  },
  i18n: {
    messages: {
      en: {
        message: {
          startRandomImage: "Explore in all images",
        }
      },
      ja: {
        message: {
          startRandomImage: "ランダムな画像でスタート",
        }
      }
    }
  }
}
</script>

<style scoped>

</style>