<template>
  <div class="text-center">
    <h3>{{ $t("message.welcome") }}</h3>
  </div>
</template>

<script>
export default {
  name: "SiteNotices",
  i18n: {
    messages: {
      en: {
        message: {
          welcome: "Welcome to Hentic.net!",
        }
      },
      ja: {
        message: {
          welcome: "Hentic.netへようこそ!",
        }
      }
    }
  }
}
</script>

<style scoped>

</style>