<template>
  <header class="header text-center">
    <div class="navbar">
      <div class="container-fluid justify-content-between">
        <div class="col">
          <router-link to="/category">Category</router-link>
        </div>
        <div class="col">
          <router-link class="navbar-brand text-dark mx-0" to="/">Hentic</router-link>
        </div>
        <div class="col">
          <div class="d-flex flex-row justify-content-center align-items-center">
            <LanguageButton class="m-md-1"/>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import LanguageButton from "@/components/common/LanguageButton"

export default {
  name: "MainHeader",
  components: {
    LanguageButton,
  }
}
</script>

<style scoped>
.header {
  background-color: aliceblue;
}
</style>