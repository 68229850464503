<template>
  <div class="slider-controller py-2 px-4">
    <div>
      <div class="d-flex flex-column flex-fill">
        <div class="flex-grow-1">
          <label class="form-label" for="speedRange">Speed</label>
          <input id="speedRange" v-model="sliderConfig.speed" class="form-range" max="20" min="1" step="0.5"
                 type="range"
                 @mouseleave="onChangedSpeed">
          <span><small>{{ getSpeedStr }}</small></span>
        </div>
        <div class="h-auto">
          <div class="play-button mx-auto" @click="onClickPlayBtn">
            <font-awesome-icon v-if="!isPlaying || isPlaying === undefined" icon="fa-solid fa-play"/>
            <font-awesome-icon v-if="isPlaying" icon="fa-solid fa-stop"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SliderController",
  props: {
    isPlaying: Boolean,
    speed: Number
  },
  data() {
    return {
      sliderConfig: {
        speed: this.speed / 1000,
        isPlaying: true,
      },
    }
  },
  computed: {
    getSpeedStr: function () {
      if (this.sliderConfig.speed % 1 === 0) {
        return `${this.sliderConfig.speed}.0`
      } else {
        return this.sliderConfig.speed
      }
    }
  },
  methods: {
    onChangedSpeed: function () {
      this.$store.commit({
        type: "changeSpeed",
        speed: Number(this.sliderConfig.speed),
      });
      this.emit();
    },
    onClickPlayBtn: function () {
      this.sliderConfig.isPlaying = !this.isPlaying;
      this.emit();
    },
    emit: function () {
      this.$emit("change", this.sliderConfig)
    }
  },
  watch: {
    speed: function () {
      this.sliderConfig.speed = this.speed / 1000
    }
  }
}
</script>

<style scoped>
.slider-controller {
  color: white;
}

.fa-play, .fa-stop {
  vertical-align: middle;
  height: 1.5rem;
}

.play-button {
  width: fit-content;
  height: fit-content;
  border: 1px solid white;
  border-radius: 3px;
  padding: .25rem 1.5rem;
  background-color: rgba(255, 255, 255, 20%);
  transition: background-color .1s ease;
}

.play-button:active {
  background-color: rgba(255, 255, 255, 50%);
}


</style>