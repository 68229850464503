<template>
  <div id="ranking1Y" class="w-100">
  </div>
</template>

<script>
import postscribe from "postscribe"
import scripts from "@/config/dlsite_scripts"

export default {
  name: "AdsRanking1Y",
  mounted() {
    postscribe("#ranking1Y", scripts.Ranking7D_Id)
    postscribe("#ranking1Y", scripts.BlogParts_Script)
  }
}
</script>

<style scoped>
#ranking24h div {
  max-width: 100%;
  max-height: 100%;
}
</style>