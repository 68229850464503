<template>
  <div class="category">
    <div>
      <img alt="" class="background" src="../assets/low_bg.jpg">
    </div>
    <div class="d-flex flex-column align-items-center justify-content-evenly h-100">
      <div style="max-width: 500px">
        <div v-for="tag in tagList" :key="tag.id" class="d-inline-block mx-1">
          <input :id="'tag_' + tag.id" v-model="selectedTags" :value="tag.id" autocomplete="off" class="btn-check"
                 type="checkbox">
          <label :for="'tag_' + tag.id" class="btn btn-outline-secondary btn-sm">{{ tag.name }} ({{
              tag.image_num
            }})</label>
        </div>
      </div>
      <div>
        <div class="btn btn-primary" @click="moveToSlider">Start</div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";

export default {
  name: "CategoryList",
  data() {
    return {
      tagList: [],
      selectedTags: []
    }
  },
  methods: {
    moveToSlider: function () {
      router.push({
        name: "main-slider",
        query: {tags: this.selectedTags}
      })
    }
  },
  mounted() {
    // get random tags
    const url = `${process.env.VUE_APP_API_URL_ORIGIN}${process.env.VUE_APP_API_PATH_TAG_ALL}?lang=${this.$i18n.locale}`
    axios.get(url).then(response => {
      this.tagList = response.data
    })
  }
}
</script>

<style scoped>

.background {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  filter: blur(10px) grayscale(50%);
}
</style>