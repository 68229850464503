const IMAGE_NUM = 10
const IMAGE_BLOCKS = 5
export const ALLOWED_LANGUAGES = {
    ja: {
        code: "ja",
        flag: "jp",
        display: "日本語"
    },
    en: {
        code: "en",
        flag: "us",
        display: "English"
    },
}
export const DEFAULT_LANGUAGES = "en"

export default {
    IMAGE_NUM,
    IMAGE_BLOCKS,
    ALLOWED_LANGUAGES,
    DEFAULT_LANGUAGES
}